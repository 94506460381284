import React, { useEffect, useRef } from "react";
import instance from "../utils/instance";
import { getCurrentDateTime } from "../utils/helpers";
import { getSessionId } from "../utils/cookieSessionGenerator";

const TrackProductVisits = ({ moqId, userId }) => {
  // Refs
  const isTracking = useRef(false);

  useEffect(() => {
    // Prevent multiple tracking attempts
    if (isTracking.current) return;

    const trackVisit = async () => {
      if (isTracking.current === true) return;
      try {
        isTracking.current = true;
        const now = getCurrentDateTime();
        const visit = { moq_id: moqId, timestamp: now };
        let sessionId = getSessionId();

        const payload = {
          cookie_session_id: sessionId,
          visited_moqs: [visit],
        };

        if (userId) {
          payload.user_id = userId;
        }

        // Call the API immediately for logged-in users
        await instance.post("page_track", payload);
      } catch (error) {
        console.error("Error sending tracked data:", error);
        isTracking.current = false;
      }
    };

    trackVisit();
  }, [moqId, userId]);

  return null;
};

export default TrackProductVisits;
