import { MOQS, ALL_MOQS, GET_MOQ, FETCH_MORE_MOQS, SEARCH_MOQS, RECENT_MOQS } from './../types/Index';
import instance from './../../utils/instance';
import axios from 'axios'

export const getMoqsByCategoryId = (id) => async dispatch => {

    try {

        const response = await instance.get(`moq/category/${id}`);

        // console.log(response)

        if (response.data && response.data.moqs) {

            dispatch({ type: MOQS, payload: response.data.moqs });

        }

        return response.data;
        
    } catch (error) {

        throw error;
        
    }

};

export const getMoqs = () => async dispatch => {

    try {

        const response = await instance.get(`moq`);

        let payload = {};

        payload.data = response.data.moqs;

        payload.pages = response.data.pages;

        payload.page = 1;

        payload.next_page = 2;

        dispatch({ type: ALL_MOQS, payload });

        return response.data;
        
    } catch (error) {

        throw error;
        
    }

};

export const getActiveMoqs = () => async dispatch => {

    try {

        const response = await instance.get(`moq/active`);

        let payload = {};

        payload.data = response.data.moqs;

        payload.pages = response.data.pages;

        payload.page = 1;

        payload.next_page = 2;

        dispatch({ type: ALL_MOQS, payload });

        // console.log(response)

        return response.data;
        
    } catch (error) {

        throw error;
        
    }

};


export const getMoreMoqs = (next_page) => async dispatch => {

    try {

        const response = await instance.get(`moq?page=${next_page}`);

        if(!response.data.hasOwnProperty('moqs')) {

            return response.data;

        };

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.moqs;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_MOQS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};

export const getActiveMoreMoqs = (next_page) => async dispatch => {

    try {

        const response = await instance.get(`moq/active?page=${next_page}`);

        if(!response.data.hasOwnProperty('moqs')) {

            return response.data;

        };

        if(response.data !== "") {

            let payload = {};

            payload.data = response.data.moqs;

            payload.pages = response.data.pages;

            payload.page = next_page;

            payload.next_page = next_page + 1;

            dispatch({ type: FETCH_MORE_MOQS, payload });

        }

        return response.data;
        
    } catch (error) {

        // console.log(error);

        if(error.response !== undefined && error.response.status === 401) {

            return window.location.href = "/signin";

        }

        throw (error);
        
    }

};


export const getMoqById = (id) => async dispatch => {

    try {

        const response = await instance.get(`moq/${id}`);

        dispatch({ type: GET_MOQ, payload: response.data });

        return response.data;
        
    } catch (error) {

        throw error;
        
    }

};

export const searchMoqByTerm = (term) => async dispatch => {

    try {

        const response = await axios.post(`https://api.litudian.com/api/v1/search/moqs`, {  product_name: term });

        if (response?.data) {

            dispatch({ type: SEARCH_MOQS, payload: response.data });

        }

        return response;
        
    } catch (error) {

        throw error;
        
    }

};

export const getRecentMoqs = () => async dispatch => {

    try {

        const response = await instance.get(`moq/recent`);

        if (response?.data && response?.data?.moqs) {

            dispatch({ type: RECENT_MOQS, payload: response?.data?.moqs });

        }

        return response.data;
        
    } catch (error) {

        throw error;
        
    }

};