import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

export const getSessionId = () => {
  let sessionId = Cookies.get("cookie_session_id");

  if (!sessionId) {
    sessionId = uuidv4(); // Generate new UUID
    Cookies.set("cookie_session_id", sessionId, { path: "/" }); // Store in cookie
  }

  return sessionId;
};
