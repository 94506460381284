import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "./img/logo.png";
import tiktok from "./img/tiktok.svg";
import instagram from "./img/instagram.svg";
import twitter from "./img/twitter.svg";
import facebook from "./img/facebook.svg";
import AuthModal from "./../modals/Auth";
import DeliveryLocationModal from "./../modals/DeliveryLocation";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import RequestModal from "../modals/RequestMOQModal";
import TrackOrderModal from "../modals/TrackOrderModal";
import { Menu, Popover, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showUser } from "./../store/actions/UserActions";
import { searchMoqByTerm } from "./../store/actions/MoqActions";
import { getCategories } from "./../store/actions/CategoryActions";
import { toast } from "react-toastify";
import ThemeSwitcher from "../components/ThemeSwitcher";
import withRouter from "../utils/withRouter";
import { toTitleCase, tokenExpired } from "../utils/helpers";
import { AuthModalContext } from "./../contexts/auth";
import { DeliveryLocationModalContext } from "./../contexts/deliverylocation";
import { getDeliveryLocations } from "./../store/actions/DeliveryLocationActions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CookieConsent from "react-cookie-consent";
import { withCookies } from "react-cookie";
import { isAuthenticated } from "../utils/helpers";

const authenticated = isAuthenticated();

function Main(props) {
  // Props
  const {
    categories: { data },
    moqs: { search_moqs },
    cookies,
  } = props;

  // contexts
  const { toggleModal } = useContext(AuthModalContext);
  const { toggleModal: toggleDeliveryLocationModal } = useContext(
    DeliveryLocationModalContext
  );

  // States
  const [showTrackOrderModal, setShowTrackOrderModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [card_items, setCardItems] = useState([]);
  const [search, setSearch] = useState("");
  const [term, setTerm] = useState("");

  // Refs
  const dropdownRef = React.createRef(null);

  // Effects
  useEffect(() => {
    async function fetchDeliveryLocations() {
      try {
        const result = await props.getDeliveryLocations();
        if (authenticated && result?.length === 0) {
          toggleDeliveryLocationModal();
        }
        if (authenticated) {
          let uid = localStorage.getItem("ltdn_uid");
          await props.showUser(uid);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (authenticated) fetchDeliveryLocations();
    async function fetchCategories() {
      try {
        await props.getCategories();
      } catch (error) {
        console.log(error);
      }
    }
    fetchCategories();
    const broadcastChannel = new BroadcastChannel("LitudianChannel");
    broadcastChannel.addEventListener("message", handleMessage);
    const expired = tokenExpired();
    if (expired) {
      toggleModal();
      cookies.remove("ltdn_exp");
      toast.error(`Your user session had expired. Please login again !`, {
        autoClose: 10000,
      });
    }
    document.addEventListener("click", handleClickOutside);
    setCardItems(JSON.parse(localStorage.getItem("bids")) || []);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      const broadcastChannel = new BroadcastChannel("LitudianChannel");
      broadcastChannel.removeEventListener("message", handleMessage);
    };
  }, []);

  // Handling query string with useEffect
  useEffect(() => {
    if (window.location && window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      const value = queryParams.get("action");
      if (value) {
        renderModal(value);
      }
    }
  }, []);

  // Create a listener for the Broadcast Channel API
  const handleMessage = (event) => {
    if (event.data === "loginSuccess") {
      // Refresh the app
      window.location.reload();
    } else if (event.data === "refresh-cart") {
      setTimeout(() => {
        let bds = JSON.parse(localStorage.getItem("bids")) || [];
        setCardItems(bds);
      }, 3000);
    }
  };

  const toggleForgotPasswordModal = (val) =>
    val !== undefined && setForgotPassword(val);

  const fetchTerm = async (value) => {
    try {
      await props?.searchMoqByTerm(value);

      setShowAutoComplete(true);
    } catch (error) {
      if (error?.response?.data && error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, { autoClose: 10000 });
      }

      console.log(error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowAutoComplete(false);
    }
  };

  const handleClickAway = (e) => {
    setShowSidebar((prev) => !prev);
  };

  const renderModal = (tab) => {
    switch (tab) {
      case "request-moq-campaign":
        if (!authenticated) {
          toggleModal();
        } else {
          setShowRequestModal(true);
        }
        break;
      case "track-order":
        if (!authenticated) {
          toggleModal();
        } else {
          setShowTrackOrderModal(true);
        }
        break;
      default:
        break;
    }
  };

  let uid = localStorage.getItem("ltdn_uid");
  
  return (
    <React.Fragment>
      <div className="min-h-screen bg-white dark:bg-[#252525] flex flex-col font-bold">
        <div className="flex flex-col w-full fixed z-40">
          <div className="w-full flex flex-col">
            <div className="flex-1 flex flex-row justify-between items-center md:space-x-10 bg-[#ECEBEB] dark:bg-[#5B5B5B] py-2 px-2 md:px-[5%]">
              <div className="flex flex-row items-center space-x-3">
                <button
                  className="md:hidden bg-white rounded-lg p-1 text-[#A68F69]"
                  onClick={() => setShowSidebar((prev) => !prev)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                    />
                  </svg>
                </button>
                <Link to="/">
                  <img
                    className="h-16 md:h-20 object-contain"
                    src={logo}
                    alt="litudian"
                  />
                </Link>
              </div>

              <div
                ref={dropdownRef}
                className="hidden md:flex flex-row items-center w-full relative"
              >
                <div className="flex flex-row flex-1 h-full items-center bg-white rounded-full px-5">
                  <input
                    type="text"
                    className="flex-1 py-2 px-4 rounded-full bg-white focus:outline-none placeholder:text-[#B3B3B3] placeholder:font-bold"
                    placeholder="Search..."
                    value={term}
                    onChange={(e) => {
                      setTerm(e?.target?.value);

                      if (e?.target?.value) {
                        fetchTerm(e?.target?.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && term !== "") {
                        return (window.location.href = `/search?q=${term}`);
                      }
                    }}
                    onFocus={() => {
                      if (search_moqs && search_moqs?.length > 0) {
                        setShowAutoComplete(true);
                      }
                    }}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-[#B3B3B3]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>

                {showAutoComplete && search_moqs && search_moqs?.length > 0 && (
                  <ul className="absolute left-10 w-fit top-10 bg-gray-100 text-gray-600 px-2 py-2 z-50 rounded-b-md shadow space-y-1">
                    {search_moqs?.map((moq) => (
                      <li
                        key={moq?.id}
                        className="py-1 px-2 bg-gray-200 hover:bg-[#A68F69] hover:text-white rounded-sm"
                      >
                        <Link
                          to={`/products/${moq?.moq_slug}`}
                          onClick={() => setShowAutoComplete(false)}
                        >
                          <div className="w-full h-fit flex flex-row justify-start items-center p-2 cursor-pointer space-x-5 border-b last:border-b-0">
                            <LazyLoadImage
                              className="w-10 h-10 border border-gray-400"
                              src={
                                moq?.product[0]?.images[0] ||
                                "https://via.placeholder.com/300"
                              }
                              alt={
                                moq?.product[0]?.name
                                  ? toTitleCase(moq?.product[0]?.name)
                                  : "-"
                              }
                            />

                            <p className="font-bold capitalize text-base truncate">
                              {moq?.product[0]?.name
                                ? toTitleCase(moq?.product[0]?.name)
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="flex flex-row items-center space-x-4 md:hidden">
                <button
                  className="bg-white rounded-lg p-1 text-[#A68F69]"
                  onClick={() => setShowMobileSearch((prev) => !prev)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
                <Popover className="relative">
                  <Popover.Button className="bg-white rounded-lg p-1 text-[#A68F69]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                      />
                    </svg>
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10 -left-44 w-[200px] bg-white p-4 rounded mt-2">
                    <div className="grid grid-cols-4">
                      <button className="bg-white rounded-lg p-1 text-[#A68F69]">
                        <ThemeSwitcher />
                      </button>

                      <Link
                        className="bg-white rounded-lg p-1 text-[#A68F69] relative"
                        to="/cart"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                        </svg>

                        {card_items?.length > 0 && (
                          <span className="absolute -right-0 -top-2 bg-red-600 text-white text-xs px-[6px] py-[2px] rounded shadow">
                            {card_items?.length}
                          </span>
                        )}
                      </Link>

                      {!authenticated && (
                        <button
                          className="bg-white rounded-lg p-1 text-[#A68F69]"
                          onClick={() => toggleModal()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      )}

                      {authenticated && (
                        <button
                          className="bg-white rounded-lg p-1 text-[#A68F69]"
                          onClick={() => {
                            cookies.remove("ltdn");

                            localStorage.removeItem("ltdn_uid");

                            cookies.remove("ltdn_exp");

                            toast.warning(`You are logged out !`, {
                              autoClose: 10000,
                            });

                            return (window.location.href = "/");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </Popover.Panel>
                </Popover>
              </div>

              <div className="flex-row items-center space-x-4 hidden md:flex">
                <button className="bg-white rounded-lg p-1 text-[#A68F69]">
                  <ThemeSwitcher />
                </button>

                <Link
                  className="bg-white rounded-lg p-1 text-[#A68F69] relative"
                  to="/cart"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                  </svg>

                  {card_items?.length > 0 && (
                    <span className="absolute -right-2 -top-2 bg-red-600 text-white text-xs px-[6px] py-[2px] rounded shadow">
                      {card_items?.length}
                    </span>
                  )}
                </Link>

                {!authenticated && (
                  <button
                    className="bg-white rounded-lg p-1 text-[#A68F69]"
                    onClick={() => toggleModal()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}

                {authenticated && (
                  <button
                    className="bg-white rounded-lg p-1 text-[#A68F69]"
                    onClick={() => {
                      cookies.remove("ltdn");

                      // localStorage.removeItem("ltdn_uid");

                      cookies.remove("ltdn_exp");

                      toast.warning(`You are logged out !`, {
                        autoClose: 10000,
                      });

                      return (window.location.href = "/");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>

            <div className="bg-[#73240B] text-white py-2 md:py-1 min-h-[19px] hidden md:block">
              <ul className="flex flex-col md:flex-row md:justify-evenly md:items-center">
                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm ${
                    window.location.pathname === "/"
                      ? "text-[#FAA51A]"
                      : "text-white"
                  }`}
                >
                  <Link to="/">Home</Link>
                </li>

                <li className="md:hidden">
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <Menu.Button className="bg-transparent text-white rounded-l-full shadow-sm px-3 flex flex-row items-center justify-between font-bold space-x-2">
                      <span className="font-bold uppercase text-sm">
                        Categories
                      </span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </Menu.Button>

                    <Menu.Items className="absolute z-50 left-2 md:right-0 w-3/4 md:w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="rounded p-[2px]">
                        {data.map((category, i) => (
                          <Menu.Item key={i}>
                            {({ active }) => (
                              <div
                                className={`hover:bg-[#73240B] group flex flex-row items-center w-full py-2 cursor-pointer rounded-sm`}
                                onClick={() =>
                                  (window.location.href = `/categories/${category.slug}`)
                                }
                              >
                                <span className="text-base px-3 text-[#73240B] group-hover:text-white capitalize">
                                  {category.name.toLowerCase()}
                                </span>
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Menu>
                </li>

                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm ${
                    window.location.pathname === "/campaigns"
                      ? "text-[#FAA51A]"
                      : "text-white"
                  }`}
                >
                  <Link to="/campaigns">MOQ Campaigns</Link>
                </li>

                {authenticated && (
                  <li
                    className={`inline-block mx-3 font-bold uppercase text-sm ${
                      window.location.pathname === "/profile"
                        ? "text-[#FAA51A]"
                        : "text-white"
                    }`}
                  >
                    <Link to="/profile">My Profile</Link>
                  </li>
                )}

                {authenticated && (
                  <li
                    className={`inline-block mx-3 font-bold uppercase text-sm ${
                      window.location.pathname === "/orders"
                        ? "text-[#FAA51A]"
                        : "text-white"
                    }`}
                  >
                    <Link to="/orders">My Orders</Link>
                  </li>
                )}

                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm text-white`}
                >
                  <button
                    className="uppercase"
                    onClick={() => {
                      if (!authenticated) {
                        toggleModal();
                      } else {
                        setShowRequestModal(true);
                      }
                    }}
                  >
                    Request MOQ Campaign
                  </button>
                </li>

                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm text-white`}
                >
                  <button
                    className="uppercase"
                    onClick={() => {
                      if (!authenticated) {
                        toggleModal();
                      } else {
                        setShowTrackOrderModal(true);
                      }
                    }}
                  >
                    Track Order
                  </button>
                </li>

                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm ${
                    window.location.pathname === "/about"
                      ? "text-[#FAA51A]"
                      : "text-white"
                  }`}
                >
                  <Link to="/about">About Us</Link>
                </li>
                <li
                  className={`inline-block mx-3 font-bold uppercase text-sm ${
                    window.location.pathname === "/contacts"
                      ? "text-[#FAA51A]"
                      : "text-white"
                  }`}
                >
                  <Link to="/contacts">Contact Us</Link>
                </li>
              </ul>
            </div>

            <Transition
              as="div"
              show={showMobileSearch}
              enter="transition ease-in-out duration-200 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0 z-0"
              leave="transition ease-in-out duration-200 transform"
              leaveFrom="translate-y-0 z-0"
              leaveTo="-translate-y-full"
            >
              <div className="w-full md:hidden flex flex-row justify-center items-center bg-[#F0F0F0] dark:bg-[#5B5B5B] px-2 py-3 relative z-[999]">
                <div className="flex flex-row items-center justify-between w-full border-b border-[#F0F0F0] dark:border-[#A68F69] space-x-3">
                  <input
                    className="bg-transparent border-x-0 border-t-0 focus:outline-none text-base text-[#A68F69] h-8 placeholder:text-[#A68F69] w-full"
                    name="search"
                    type="text"
                    placeholder={"Search..."}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && search !== "") {
                        return (window.location.href = `/search?q=${search}`);
                      }
                    }}
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                  />
                </div>
                <button
                  className="text-[#A68F69] px-2 rounded py-1"
                  onClick={() => (window.location.href = `/search?q=${search}`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </div>
            </Transition>
            <Transition
              show={showSidebar}
              enter="transition-all ease-in-out duration-100"
              enterFrom="opacity-25 max-h-0"
              enterTo="opacity-100 max-h-xl"
              leave="transition-all ease-in-out duration-300"
              leaveFrom="opacity-100 max-h-xl"
              leaveTo="opacity-0 max-h-0"
            >
              <div className="fixed flex flex-row w-full h-screen z-[999] top-0">
                <div className="flex flex-col min-h-screen h-full bg-white dark:bg-[#252525] w-4/5 py-4 px-4 space-y-6 overflow-y-scroll">
                  <div className="flex flex-row w-full items-center py-1 px-1 justify-between">
                    <Link to="/">
                      <img
                        className="h-16 object-contain"
                        src={logo}
                        alt="litudian"
                      />
                    </Link>
                    <button
                      className="dark:text-white"
                      onClick={() => setShowSidebar((prev) => !prev)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col h-full space-y-3 divide-y divide-gray-500 pb-10">
                    <ul className="flex flex-col md:flex-row md:justify-evenly md:items-center">
                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                          window.location.pathname === "/"
                            ? "text-[#FAA51A]"
                            : "text-gray-800 dark:text-white"
                        }`}
                      >
                        <Link to="/" onClick={() => setShowSidebar(true)}>
                          Home
                        </Link>
                      </li>

                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                          window.location.pathname === "/campaigns"
                            ? "text-[#FAA51A]"
                            : "text-gray-800 dark:text-white"
                        }`}
                      >
                        <Link
                          to="/campaigns"
                          onClick={() => setShowSidebar(true)}
                        >
                          MOQ Campaigns
                        </Link>
                      </li>

                      {authenticated && (
                        <li
                          className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                            window.location.pathname === "/profile"
                              ? "text-[#FAA51A]"
                              : "text-gray-800 dark:text-white"
                          }`}
                        >
                          <Link
                            to="/profile"
                            onClick={() => setShowSidebar(true)}
                          >
                            My Profile
                          </Link>
                        </li>
                      )}

                      {authenticated && (
                        <li
                          className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                            window.location.pathname === "/orders"
                              ? "text-[#FAA51A]"
                              : "text-gray-800 dark:text-white"
                          }`}
                        >
                          <Link
                            to="/orders"
                            onClick={() => setShowSidebar(true)}
                          >
                            My Orders
                          </Link>
                        </li>
                      )}

                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm text-gray-800 dark:text-white`}
                      >
                        <button
                          className="uppercase"
                          onClick={() => {
                            if (!authenticated) {
                              toggleModal();
                            } else {
                              setShowRequestModal(true);
                              setShowSidebar(true);
                            }
                          }}
                        >
                          Request MOQ Campaign
                        </button>
                      </li>

                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm text-gray-800 dark:text-white`}
                      >
                        <button
                          className="uppercase"
                          onClick={() => {
                            if (!authenticated) {
                              toggleModal();
                            } else {
                              setShowTrackOrderModal(true);
                            }
                          }}
                        >
                          Track Order
                        </button>
                      </li>

                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                          window.location.pathname === "/about"
                            ? "text-[#FAA51A]"
                            : "text-gray-800 dark:text-white"
                        }`}
                      >
                        <Link to="/about" onClick={() => setShowSidebar(true)}>
                          About Us
                        </Link>
                      </li>
                      <li
                        className={`py-2 inline-block mx-3 font-bold uppercase text-sm ${
                          window.location.pathname === "/contacts"
                            ? "text-[#FAA51A]"
                            : "text-gray-800 dark:text-white"
                        }`}
                      >
                        <Link
                          to="/contacts"
                          onClick={() => setShowSidebar(true)}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    {data?.length > 0 && (
                      <div className="flex flex-col">
                        <h1 className="py-4 inline-block mx-3 font-bold uppercase text-sm text-tangerine">
                          Our Categories
                        </h1>
                        <ul className="flex flex-col md:flex-row md:justify-evenly md:items-center">
                          {data.map((category, i) => (
                            <li
                              key={i}
                              className={`py-2 inline-block mx-3 font-bold uppercase text-sm  ${
                                window.location.pathname ===
                                `/categories/${category.slug}`
                                  ? "text-[#FAA51A]"
                                  : "text-gray-800 dark:text-white"
                              }`}
                            >
                              <a
                                href={`/categories/${category.slug}`}
                                className="uppercase"
                                onClick={() => setShowSidebar(true)}
                              >
                                {category.name.toLowerCase()}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="hidden flex-row items-center justify-start w-full space-x-6 py-10 px-3">
                      <button className="bg-white dark:bg-[#222] rounded-lg p-1 text-[#A68F69]">
                        <ThemeSwitcher />
                      </button>

                      <Link
                        className="bg-white dark:bg-[#222] rounded-lg p-1 text-[#A68F69] relative"
                        to="/cart"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                        </svg>

                        {card_items?.length > 0 && (
                          <span className="absolute -right-2 -top-2 bg-red-600 text-white text-xs px-[6px] py-[2px] rounded shadow">
                            {card_items?.length}
                          </span>
                        )}
                      </Link>

                      {!authenticated && (
                        <button
                          className="bg-white dark:bg-[#222] rounded-lg p-1 text-[#A68F69]"
                          onClick={() => toggleModal()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      )}

                      {authenticated && (
                        <button
                          className="bg-white dark:bg-[#222] rounded-lg p-1 text-[#A68F69]"
                          onClick={() => {
                            cookies.remove("ltdn");

                            localStorage.removeItem("ltdn_uid");

                            cookies.remove("ltdn_exp");

                            toast.warning(`You are logged out !`, {
                              autoClose: 10000,
                            });

                            return (window.location.href = "/");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  onClick={handleClickAway}
                  className="flex flex-col flex-auto bg-gray-800 opacity-50"
                ></div>
              </div>
            </Transition>
          </div>
        </div>

        <div className="flex flex-col w-full mt-20 md:mt-[120px]">
          <div className="w-full flex flex-col">
            {data.length > 0 && (
              <div className="md:flex flex-row bg-gray-100 dark:bg-[#5B5B5B] py-6 px-2 md:px-[5%] space-x-12 hidden shadow-sm">
                <h1 className="text-tangerine">Categories</h1>

                <div className="flex-1 grid grid-cols-5 gap-3">
                  {data.map((category, i) => (
                    <a
                      key={i}
                      title={category.name}
                      href={`/categories/${category.slug}`}
                      className="text-[#A68F69] dark:text-gray-100 hover:text-litudian-orange dark:hover:text-litudian-orange font-extrabold capitalize truncate"
                    >
                      {category.name.toLowerCase()}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mx-auto px-4 md:px-[5%] w-full min-h-screen py-2">
          {props.children}
        </div>

        <div className="w-full bg-[#A68F69]">
          <div className="max-w-9xl mx-auto px-4 py-8 md:px-8 w-full grid grid-cols-1 md:grid-cols-4 gap-y-7 md:gap-y-10 md:divide-x">
            <div className="flex flex-col justify-center items-start md:px-5 space-y-3">
              <h1 className="text-white">Connect with us on social media</h1>

              <div className="flex flex-row items-center justify-start space-x-3">
                <a
                  href="https://www.instagram.com/litudian/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={instagram}
                    alt="instagram"
                  />
                </a>

                <a
                  href="https://web.facebook.com/litudian"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={facebook}
                    alt="facebook"
                  />
                </a>

                <a
                  href="https://www.tiktok.com/@litudiankenya"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={tiktok}
                    alt="tiktok"
                  />
                </a>

                <a
                  href="https://twitter.com/Litudian"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
              </div>
            </div>

            <div className="flex flex-col justify-center items-start md:px-5">
              <h1 className="text-white capitalize font-bold text-base">
                Quick Links
              </h1>
              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/about">About Us</Link>
              </li>

              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/contacts">Contact Us</Link>
              </li>

              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/terms">Terms of Service</Link>
              </li>

              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/privacy">Privacy Policy</Link>
              </li>

              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/cookie-policy">Cookie Policy</Link>
              </li>
            </div>

            <div className="flex flex-col justify-center items-start md:px-5">
              <h1 className="text-white capitalize font-bold text-base">
                Contact Us
              </h1>

              <li className="inline-block md:mx-3 text-base text-white font-normal">
                Family Trade Center, 4th Floor - F89, Tom Mboya Street, Nairobi.
              </li>
              <li className="inline-block md:mx-3 text-base text-white font-normal">
                Email: hello@litudian.com
              </li>
              <li className="inline-block md:mx-3 text-base text-white font-normal">
                Telephone: +254792819290
              </li>
              <li className="inline-block md:mx-3 text-base text-white font-normal">
                <Link to="/contacts#faqs">Frequently Asked Questions</Link>
              </li>
            </div>

            <div className="flex flex-col justify-center items-start md:px-5">
              <img
                src="https://litudian.s3.ap-south-1.amazonaws.com/F3NN99J1VMJR.png"
                alt="proud to partner with microsoft for startups"
              />
            </div>
          </div>
          <center>
            <span className="text-white font-bold">
              Litudian &copy; {new Date().getFullYear()}
            </span>
          </center>
        </div>

        <AuthModal
          logo={logo}
          toggleForgotPasswordModal={toggleForgotPasswordModal}
        />
        <DeliveryLocationModal />
        <RequestModal
          isOpen={showRequestModal}
          setIsOpen={setShowRequestModal}
        />
        <TrackOrderModal
          isOpen={showTrackOrderModal}
          setIsOpen={setShowTrackOrderModal}
        />
        <ForgotPasswordModal
          isOpen={forgotPassword}
          setIsOpen={toggleForgotPasswordModal}
          toggleAuthModal={toggleModal}
        />
      </div>
      <CookieConsent
        buttonClasses="bg-litudian-orange text-white"
        buttonStyle={{ backgroundColor: "#FF4C00", color: "#fff" }}
        style={{
          backgroundColor: "#fff",
          color: "#000",
          boxShadow: "inset 0px 8px 19px -3px rgba(0,0,0,0.1)",
        }}
        contentClasses="font-futura"
      >
        Litudian uses cookies to enhance your experience on our platform,
        analyze site usage, personalize content and assist in our marketing
        efforts. By continuing to use our website, you agree to our use of
        cookies. For more information, please review our{" "}
        <b>
          <a href="/cookie-policy" target="_blank" className="font-futuraBold">
            Cookie Policy
          </a>
        </b>
        .
      </CookieConsent>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    categories: state.categories,
    moqs: state.moqs,
    delivery_locations: state.delivery_locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showUser, getCategories, searchMoqByTerm, getDeliveryLocations },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withCookies(Main)));
